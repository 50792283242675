import axios from 'axios';
export class CommonAPIService {
  constructor() {
    this.api_url = process.env.VUE_APP_API_URL;
  }

  postCall(url, parameters, router) {
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, parameters, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error
    });
  }

  getCall(url, parameters, router) {
    const fullUrl = this.api_url + url;
    return axios.get(fullUrl, parameters, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.data)
    .catch((error) => {
      throw error
    });
  }

}

export default CommonAPIService;
