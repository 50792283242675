import CommonAPIService from './CommonHandler';

export class AIServiceHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  makeAICall(search, router) {
    const url = `/api/ai`;
    return this.CommonAPIService.postCall(url, search, router);
  }

  getAIResults(id, router) {
    const url = `/api/ai/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default AIServiceHandler;
