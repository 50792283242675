<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <label>It might take up to 3 minutes to process a response.  Please wait...</label>
        </b-col>
      </b-row>
    </card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <label><span style="font-size: 2em; font-weight: bold;">Welcome to the Jarvis J.R. AI Version 1.0</span></label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text center">
        AI Vector is not responsible for the responses from Jarvis J.R. and it might not reflect the values or company ideas.  Please use the AI at your discretion and always double check its responses for accuracy.
      </div>
    </div>
    <card>
      <div class="row">
        <div class="col-sm-12">
          <input type="text" class="form-control" v-model="search" placeholder="Ask me anything">
        </div>
      </div>
      <div class="row text-center">
        <div class="col-sm-12 text-center">
          <button class="btn btn-primary" @click="getAIResults">Ask Jarvis J.R.</button>
        </div>
      </div>
    </card>
    <card v-if="is_ran">
      {{ search_results }}
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AIServiceHandler from "@/servicehandlers/AIServiceHandler";
const aiServiceHandler = new AIServiceHandler();
import { Dialog } from 'element-ui';
  export default {
    components: {
      [Dialog.name]: Dialog,
    },
    data() {
      return {
        spinning: false,
        is_ran: false,
        search_results: null,
        search: null,
        message_id: null,
        error_txt: null,
        modals: {
          basic: false,
          error: false,
          success: false,
          files: false,
        },
      };
    },
    methods: {
      async getAIResultStatus() {
        this.spinning = true;
        await aiServiceHandler.getAIResults(this.message_id, this.$router)
        .then((response) => {
          if (response.success === true) {
            clearInterval(this.timerId);
            const message = JSON.parse(response.message.message_response);
            this.search_results = 'Answer: ' + message.choices[0].message.content;
            this.spinning = false;
          }
        })
        .catch((error) => {
          this.error_txt = 'Error: ' + error;
          this.spinning = false;
          this.openModal('error');
        });
      },
      getAIResults() {
        this.spinning = true;
        this.search_results = null;
        this.is_ran = null;
          return aiServiceHandler.makeAICall({message: this.search}, this.$router)
          .then((results) => {
            this.message_id = results.message_id;
            this.is_ran = true;
            this.spinning = false;
            this.timerId = setInterval(this.getAIResultStatus, 2000)
          })
          .catch((error) => {
            this.error_txt = 'Error: ' + error;
            this.spinning = false;
            this.openModal('error');
          })
      },
      openModal(name) {
        this.modals[name] = true
      },
      closeModal(name) {
        this.modals[name] = false
      },
    }
  }
</script>
<style>

</style>
